<template>
  <section id="dashboard-ecommerce">
    <div>
      <b-row>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between ">
              <b-card-text>
                <strong>
                  Startup Programs
                </strong>
              </b-card-text>
              <feather-icon
                size="20"
                icon="GridIcon"
              />
            </div>
            <div class="d-flex align-items-start">
              <b-card-title
                class="mr-2"
                style="font-size: 2rem"
              >
                <strong>{{ totalPrograms.count || 0 }}</strong>
              </b-card-title>
            </div>
            <div>
              <b-card-text>Applicants : {{
                totalPrograms.sum ? totalPrograms.sum.total_applicants || 0 : 0
              }}
              </b-card-text>
              <div class="d-flex justify-content-between align-items-start">
                <b-card-text>Participants : {{
                  totalPrograms.sum ? totalPrograms.sum.total_participants || 0 : 0
                }}
                </b-card-text>
                <b-link :to="{ name: 'manage-programs' }">
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="20"
                  />
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between ">
              <b-card-text>
                <strong>
                  Events
                </strong>
              </b-card-text>
              <feather-icon
                size="20"
                icon="ActivityIcon"
              />
            </div>
            <div class="d-flex align-items-start">
              <b-card-title
                class="mr-2"
                style="font-size: 2rem"
              >
                <strong>{{ totalEvents.count || 0 }}</strong>
              </b-card-title>
            </div>
            <div>
              <b-card-text>Applicants : {{
                totalEvents.sum ? totalEvents.sum.total_applicants || 0 : 0
              }}
              </b-card-text>
              <div class="d-flex justify-content-between align-items-start">
                <b-card-text>Participants : {{
                  totalEvents.sum ? totalEvents.sum.total_participants || 0 : 0
                }}
                </b-card-text>
                <b-link :to="{ name: 'event-details' }">
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="20"
                  />
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <div class="d-flex justify-content-between ">
              <b-card-text>
                <strong>
                  Competitions
                </strong>
              </b-card-text>
              <feather-icon
                size="20"
                icon="ZapIcon"
              />
            </div>
            <div class="d-flex align-items-start">
              <b-card-title
                class="mr-2"
                style="font-size: 2rem"
              >
                <strong> {{ totalCompetitions.count || 0 }}</strong>
              </b-card-title>
            </div>
            <div>
              <b-card-text>Applicants : {{
                totalCompetitions.sum ? totalCompetitions.sum.total_applicants || 0 : 0
              }}
              </b-card-text>
              <div class="d-flex justify-content-between align-items-start">
                <b-card-text>Participants : {{
                  totalCompetitions.sum ? totalCompetitions.sum.total_participants || 0 : 0
                }}
                </b-card-text>
                <b-link :to="{ name: 'manage-competitions' }">
                  <feather-icon
                    icon="ArrowRightIcon"
                    size="20"
                  />
                </b-link>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <b-row class="match-height">
      <b-col md="8">
        <startup-statistics />
      </b-col>
      <b-col md="4">
        <mentor-statistics />
      </b-col>
      <b-col>
        <program-statistics />
      </b-col>
    </b-row>
  </section>
</template>

<script>

import MentorStatistics from '@/views/dashboard/v1/components/incubator/MentorStatistics.vue'
import StartupStatistics from '@/views/dashboard/v1/components/incubator/StartupStatistics.vue'
import ProgramStatistics from '@/views/dashboard/v1/components/incubator/ProgramStatistics.vue'
import {
  BCard, BCardText, BCardTitle, BLink,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { mapGetters } from 'vuex'

export default {
  components: {
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    MentorStatistics,
    StartupStatistics,
    ProgramStatistics,
  },
  data() {
    return {
      totalCompetitions: {},
      totalEvents: {},
      totalPrograms: {},
    }
  },
  computed: {
    ...mapGetters({
      selectedOrgDetails: 'user/getSelectedOrgDetails',
    }),
  },
  apollo: {
    totalPrograms: {
      query() {
        return gql`
        query getTotalPrograms ($userOrg: Int!) {
        programs_basicinfo_aggregate(where: {organization_id_id: {_eq: $userOrg}, is_program: {_eq: true}}) {
          aggregate {
            count(columns: id, distinct: false)
            sum {
              total_applicants,
              total_participants
             }
            }
        }
      }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
        }
      },
      update: data => data.programs_basicinfo_aggregate.aggregate,
    },
    totalEvents: {
      query() {
        return gql`
        query getTotalEvents ($userOrg: Int!) {
        programs_basicinfo_aggregate(where: {organization_id_id: {_eq: $userOrg}, is_event: {_eq: true}}) {
          aggregate {
            count(columns: id, distinct: false)
            sum {
              total_applicants,
              total_participants
             }
            }
        }
      }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
        }
      },
      update: data => data.programs_basicinfo_aggregate.aggregate,
    },
    totalCompetitions: {
      query() {
        return gql`
        query getTotalCompetitions  ($userOrg: Int!)  {
        programs_basicinfo_aggregate(where: {organization_id_id: {_eq: $userOrg}, is_competition: {_eq: true}}) {
          aggregate {
            count(columns: id, distinct: false)
            sum {
              total_applicants,
              total_participants
             }
            }
        }
      }`
      },
      variables() {
        return {
          userOrg: this.selectedOrgDetails.orgId,
        }
      },
      update: data => data.programs_basicinfo_aggregate.aggregate,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
</style>
